import { FormikHelpers, useFormik } from 'formik';
import { ThematicAreaEdit, ThematicArea } from '../thematicArea';
import {
  Grid,
  TextField,
  FormHelperText,
  Typography,
  Box,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import ReactQuill from 'react-quill';
import { richTextModules } from '../../../core/utils/utility';
import DateTimePicker from 'react-datetime-picker';
import { Add, RemoveCircle } from '@mui/icons-material';
import { getMediaUrl } from '../../../core/utils/image';
import { useState } from 'react';

type ThematicAreaProps = {
  onSubmit: (
    value: ThematicAreaEdit,
    helpers: FormikHelpers<ThematicAreaEdit>
  ) => Promise<void>;
  submitting: boolean;
  thematicArea: ThematicArea;
};

const EditThematicAreaForm = ({
  onSubmit,
  submitting,
  thematicArea,
}: ThematicAreaProps) => {
  const [curThematic, setCurThematic] = useState('');
  const handleSubmit = async (
    value: ThematicAreaEdit,
    helpers: FormikHelpers<ThematicAreaEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: ThematicAreaEdit = {
    title: thematicArea.title,
    description: thematicArea.description,
    content: thematicArea.content,
    program_goal: thematicArea.program_goal,
    deadline: new Date(thematicArea.deadline),
    image: null,
    objectives: JSON.parse(thematicArea.objectives || '[]') as string[],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Content
                    </Grid>
                    <ReactQuill
                      modules={richTextModules}
                      value={formik.values.content}
                      onChange={(e) => {
                        formik.setFieldValue('content', e);
                      }}
                      placeholder=""
                    />
                    {formik.touched.content && formik.errors.content && (
                      <FormHelperText error>
                        {formik.errors.content}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="program_goal"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Program goal"
                      placeholder=""
                      value={formik.values.program_goal}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.program_goal)}
                      helperText={formik.errors.program_goal}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{
                      flexGrow: 1,
                      padding: '0 !important',
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '8px',
                      }}
                    >
                      <Typography sx={{ p: 0, mr: 1 }}>Deadline</Typography>
                      <Grid item alignItems="flex-end">
                        <DateTimePicker
                          onChange={(e) => {
                            formik.setFieldValue('deadline', e);
                          }}
                          value={formik.values.deadline || new Date()}
                          disableClock
                          format="y-MM-dd"
                        />
                      </Grid>
                    </div>
                    {formik.touched.deadline && formik.errors.deadline && (
                      <FormHelperText error>
                        {formik.errors.deadline}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                {formik.values.objectives.map((objective, i) => (
                  <Grid
                    key={`obj-${i}`}
                    container
                    spacing={1}
                    sx={{ mt: 1, maxWidth: '400px' }}
                  >
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <TextField
                        value={objective}
                        disabled={true}
                        type="text"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item alignSelf="flex-start" sx={{}}>
                      <IconButton
                        onClick={() => {
                          const objectives = [...formik.values.objectives];
                          objectives.splice(i, 1);

                          formik.setFieldValue('objectives', objectives);
                        }}
                      >
                        <RemoveCircle color="warning" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                <Grid container spacing={1} sx={{ mt: 2, maxWidth: '400px' }}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      value={curThematic}
                      onChange={(e) => {
                        setCurThematic(e.target.value);
                      }}
                      type="text"
                      size="small"
                      label="Objectives"
                      fullWidth
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{}}>
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('objectives', [
                          ...formik.values.objectives,
                          curThematic,
                        ]);
                        setCurThematic('');
                      }}
                    >
                      <Add color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          thematicArea.media.length
                            ? thematicArea.media[0]
                            : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditThematicAreaForm;
