import {
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { uploadFile } from '../../utils/api';
import constants from '../../utils/constants';
import { toastError } from '../../utils/ui/alert';
import { richTextModules } from '../../utils/utility';

type RichTextProps = {
  value: string;
  onChange: (v: string) => void;
};

const RichText = ({ value, onChange }: RichTextProps) => {
  const [uploadingImage, setUploadingImage] = useState(false);
  const quillRef: any = useRef();

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;

      if (!file) return;

      const formData = new FormData();
      formData.append('image', file);
      setUploadingImage(true);
      // await new Promise((r) => setTimeout(r, 300));
      const [imgPath, err] = await uploadFile('auth-xaea12/image', formData);
      setUploadingImage(false);

      if (err) {
        toastError(err);
        return;
      }

      const range = quillRef.current.getEditorSelection();
      const editor = quillRef.current.getEditor();
      editor.insertEmbed(
        range.index,
        'image',
        `${constants.apiAddress}/${imgPath}`
      );
    };
  }, []);

  return (
    <>
      <Dialog onClose={() => {}} open={uploadingImage}>
        <DialogContent>
          <Grid display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />

            <Typography sx={{ mt: 2 }}>Uploading image</Typography>
          </Grid>
        </DialogContent>
      </Dialog>

      <ReactQuill
        ref={quillRef}
        modules={{
          toolbar: {
            container: richTextModules.toolbar,
            handlers: {
              image: imageHandler,
            },
          },
        }}
        value={value}
        onChange={onChange}
        placeholder=""
      />
    </>
  );
};

export default RichText;
