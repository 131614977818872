import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { ThematicAreaCreate } from '../thematicArea';
import CreateThematicAreaForm from '../components/CreateThematicAreaForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const CreateThematicAreaContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: ThematicAreaCreate,
    { setFieldError }: FormikHelpers<ThematicAreaCreate>
  ) => {
    let success = false;

    const { objectives, ...data } = values;
    const formData: any = parseFormQuery(data, ['deadline']);
    objectives.forEach((objective) => {
      formData.append('objectives[]', objective);
    });

    await callApi({
      endpoint: 'auth-xaea12/causes',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('ThematicArea Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/thematic-areas" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Thematic Area</Typography>
        </Grid>
        <CreateThematicAreaForm
          onSubmit={handleSubmit}
          submitting={submitting}
        />
      </Grid>
    </Layout>
  );
};

export default CreateThematicAreaContainer;
