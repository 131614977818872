import { FormikHelpers, useFormik } from 'formik';
import { ResourceEdit, Resource } from '../resource';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
} from '@mui/material';
import FileContainer from '../../../core/ui/utility/FileContainer';

type ResourceProps = {
  onSubmit: (
    value: ResourceEdit,
    helpers: FormikHelpers<ResourceEdit>
  ) => Promise<void>;
  submitting: boolean;
  resource: Resource;
};

const EditResourceForm = ({
  onSubmit,
  submitting,
  resource,
}: ResourceProps) => {
  const handleSubmit = async (
    value: ResourceEdit,
    helpers: FormikHelpers<ResourceEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: ResourceEdit = {
    title: resource.title,
    category: resource.category,
    description: resource.description,
    attachment: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="Good Practice">Good Practice</MenuItem>
                        <MenuItem value="Statement">Statement</MenuItem>
                        <MenuItem value="Policies & Guidelines">
                          Policies & Guidelines
                        </MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Attachment
                    </Grid>
                    <Grid>
                      <FileContainer media={resource.media[0]} />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'attachment',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="attachment"
                      />
                    </Box>
                    {formik.touched.attachment && formik.errors.attachment && (
                      <FormHelperText error>
                        {formik.errors.attachment}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditResourceForm;
