import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { BlogEdit, Blog } from '../blog';
import EditBlogForm from '../components/EditBlogForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const EditBlogContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [blog, setBlog] = useState<Blog | null>(null);

  const fetchBlog = () =>
    fetchData(`blogs/${id}`, {
      onSuccess: (data: Blog) => {
        setBlog(data);
      },
    });

  useEffect(() => {
    fetchBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: BlogEdit,
    { setFieldError }: FormikHelpers<BlogEdit>
  ) => {
    const formData: any = parseFormQuery(values, ['published_date']);

    await callApi({
      endpoint: `auth-xaea12/blogs/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchBlog();
        toastMessage('News Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/news" />}>
      <Loader loading={loading || !blog}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit News</Typography>
          </Grid>
          <EditBlogForm
            blog={blog!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditBlogContainer;
