/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import FileContainer from '../../../core/ui/utility/FileContainer';
import { toastError } from '../../../core/utils/ui/alert';
import { JobApplication } from '../vacancy';

const JobApplicationList = () => {
  const { id } = useParams();
  const [data, setData] = useState<JobApplication[]>([]);
  const { fetchData } = useFetchApiData();

  useEffect(() => {
    console.log(`auth-xaea12/job-applications/vacancy/${id}`);
    fetchData(`auth-xaea12/job-applications/vacancy/${id}`, {
      onSuccess: (res) => {
        // setData([]);
        setData(res);
        // setData(res);
      },
      onError: toastError,
    });
  }, []);

  return (
    <>
      {/* {JSON.stringify(data)} */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>CV</TableCell>
              <TableCell>Date Applied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((job, i) => (
              <TableRow key={`job-${i}`}>
                <TableCell>{job.first_name}</TableCell>
                <TableCell>{job.last_name}</TableCell>
                <TableCell>{job.email}</TableCell>
                <TableCell>{job.phone}</TableCell>
                <TableCell>
                  <FileContainer media={job.media[0]} />
                </TableCell>
                <TableCell>{job.created_at.substring(0, 10)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default JobApplicationList;
