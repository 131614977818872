import { Box } from "@mui/material";
import { useState } from "react";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Layout from "../../../core/ui/layout/Layout";
import TableContainer from "../../../core/ui/table/TableContainer";
import { TableHeader } from "../../../core/ui/table/tableTypes";
import { Paginated } from "../../../core/utils/types";
import { parseQuery } from "../../../core/utils/utility";
import { Subscriptions } from "../subscriptions";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";

const tableHeaders: TableHeader[] = [
  { field: 'email', label: 'Email', align: 'left' },
];
const SubscriptionsList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {

    await fetchData(`auth-xaea12/subscriptions${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Subscriptions>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-xaea12/subscriptions/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage) toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };
  
  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <TableContainer
            modelToken="subscriptions"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Subscriptionss"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{ 
              edit: "/subscriptions/edit",
              create: "/subscriptions/create",
              delete: "/subscriptions",
              view: "/subscriptions",
             }}
            settings={{
              canCreate: false,
              canEdit: false,
              canDelete: true,
              canViewItem: false
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default SubscriptionsList;
