
import { FormikHelpers, useFormik } from "formik";
import { VideoEdit, Video } from "../video";
import { Grid, TextField, Paper, Box, Button } from "@mui/material";


type VideoProps = {
  onSubmit: (
    value: VideoEdit,
    helpers: FormikHelpers<VideoEdit>
  ) => Promise<void>;
  submitting: boolean;
  video: Video;
};

const EditVideoForm = ({
  onSubmit,
  submitting,
  video
}: VideoProps) => {
  const handleSubmit = async (
    value: VideoEdit,
    helpers: FormikHelpers<VideoEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: VideoEdit =  {
    title: video.title,
    url: video.url,

  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1 }}>
  <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={1}>
    
<Grid item xs={12} sm={9}>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="url"
    size="small"
    type="text"
    variant="outlined"
    label="Url"
    placeholder=""
    value={formik.values.url}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.url)}
    helperText={formik.errors.url}
  />
</Grid>
</Grid>
  </Paper>
</Grid>

    
<Grid item xs={12} sm={3}>
  <Paper sx={{ p: 2 }}>
    <Grid container>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

    </Grid>
    </form>
  </Box>
</>
  
  );
};

export default EditVideoForm;
