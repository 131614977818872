import { FormikHelpers, useFormik } from 'formik';
import { BidEdit, Bid } from '../bid';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
} from '@mui/material';
import FileContainer from '../../../core/ui/utility/FileContainer';

type BidProps = {
  onSubmit: (value: BidEdit, helpers: FormikHelpers<BidEdit>) => Promise<void>;
  submitting: boolean;
  bid: Bid;
};

const EditBidForm = ({ onSubmit, submitting, bid }: BidProps) => {
  const handleSubmit = async (
    value: BidEdit,
    helpers: FormikHelpers<BidEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: BidEdit = {
    title: bid.title,
    description: bid.description || '',
    file: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      File
                    </Grid>
                    <Grid>
                      <FileContainer media={bid.media[0]} />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'file',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="file"
                      />
                    </Box>
                    {formik.touched.file && formik.errors.file && (
                      <FormHelperText error>
                        {formik.errors.file}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditBidForm;
