import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListEventPage from '../../features/event/pages/ListEventPage';
import CreateEventPage from '../../features/event/pages/CreateEventPage';
import EditEventPage from '../../features/event/pages/EditEventPage';
import ListBlogPage from '../../features/blog/pages/ListBlogPage';
import CreateBlogPage from '../../features/blog/pages/CreateBlogPage';
import EditBlogPage from '../../features/blog/pages/EditBlogPage';
import ListSuccessStoryPage from '../../features/successStory/pages/ListSuccessStoryPage';
import CreateSuccessStoryPage from '../../features/successStory/pages/CreateSuccessStoryPage';
import EditSuccessStoryPage from '../../features/successStory/pages/EditSuccessStoryPage';
import CreateImpactPage from '../../features/impact/pages/CreateImpactPage';
import ListGalleryPage from '../../features/gallery/pages/ListGalleryPage';
import CreateGalleryPage from '../../features/gallery/pages/CreateGalleryPage';
import EditGalleryPage from '../../features/gallery/pages/EditGalleryPage';
import ListBidPage from '../../features/bid/pages/ListBidPage';
import CreateBidPage from '../../features/bid/pages/CreateBidPage';
import EditBidPage from '../../features/bid/pages/EditBidPage';
import ListTeamPage from '../../features/team/pages/ListTeamPage';
import CreateTeamPage from '../../features/team/pages/CreateTeamPage';
import EditTeamPage from '../../features/team/pages/EditTeamPage';
import ListContactPage from '../../features/contact/pages/ListContactPage';
import ListSubscriptionsPage from '../../features/subscriptions/pages/ListSubscriptionsPage';
import ListThematicAreaPage from '../../features/thematicArea/pages/ListThematicAreaPage';
import CreateThematicAreaPage from '../../features/thematicArea/pages/CreateThematicAreaPage';
import EditThematicAreaPage from '../../features/thematicArea/pages/EditThematicAreaPage';
import ListResourcePage from '../../features/resource/pages/ListResourcePage';
import CreateResourcePage from '../../features/resource/pages/CreateResourcePage';
import EditResourcePage from '../../features/resource/pages/EditResourcePage';
import ListVacancyPage from '../../features/vacancy/pages/ListVacancyPage';
import CreateVacancyPage from '../../features/vacancy/pages/CreateVacancyPage';
import EditVacancyPage from '../../features/vacancy/pages/EditVacancyPage';
import ListVideoPage from '../../features/video/pages/ListVideoPage';
import CreateVideoPage from '../../features/video/pages/CreateVideoPage';
import EditVideoPage from '../../features/video/pages/EditVideoPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        {/* Event Routes  */}
        <Route
          path="/events"
          element={
            <PrivateRoute>
              <ListEventPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/create"
          element={
            <PrivateRoute>
              <CreateEventPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/edit/:id"
          element={
            <PrivateRoute>
              <EditEventPage />
            </PrivateRoute>
          }
        />

        {/* Blog ROutes  */}
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <ListBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/create"
          element={
            <PrivateRoute>
              <CreateBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/edit/:id"
          element={
            <PrivateRoute>
              <EditBlogPage />
            </PrivateRoute>
          }
        />

        {/* Success Story Routes  */}
        <Route
          path="/success-stories"
          element={
            <PrivateRoute>
              <ListSuccessStoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/success-stories/create"
          element={
            <PrivateRoute>
              <CreateSuccessStoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/success-stories/edit/:id"
          element={
            <PrivateRoute>
              <EditSuccessStoryPage />
            </PrivateRoute>
          }
        />

        {/* Impact Routes  */}
        <Route
          path="/impacts"
          element={
            <PrivateRoute>
              <CreateImpactPage />
            </PrivateRoute>
          }
        />

        {/* Gallery Routes  */}
        <Route
          path="/galleries"
          element={
            <PrivateRoute>
              <ListGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/create"
          element={
            <PrivateRoute>
              <CreateGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/edit/:id"
          element={
            <PrivateRoute>
              <EditGalleryPage />
            </PrivateRoute>
          }
        />

        {/* Bid Rotues  */}
        <Route
          path="/bids"
          element={
            <PrivateRoute>
              <ListBidPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/bids/create"
          element={
            <PrivateRoute>
              <CreateBidPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/bids/edit/:id"
          element={
            <PrivateRoute>
              <EditBidPage />
            </PrivateRoute>
          }
        />

        {/* Team  */}

        <Route
          path="/teams"
          element={
            <PrivateRoute>
              <ListTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/create"
          element={
            <PrivateRoute>
              <CreateTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/edit/:id"
          element={
            <PrivateRoute>
              <EditTeamPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <ListContactPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <ListSubscriptionsPage />
            </PrivateRoute>
          }
        />

        {/* Thematic  */}
        <Route
          path="/thematic-areas"
          element={
            <PrivateRoute>
              <ListThematicAreaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/thematic-areas/create"
          element={
            <PrivateRoute>
              <CreateThematicAreaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/thematic-areas/edit/:id"
          element={
            <PrivateRoute>
              <EditThematicAreaPage />
            </PrivateRoute>
          }
        />

        {/* Resource  */}
        <Route
          path="/resources"
          element={
            <PrivateRoute>
              <ListResourcePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/create"
          element={
            <PrivateRoute>
              <CreateResourcePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/edit/:id"
          element={
            <PrivateRoute>
              <EditResourcePage />
            </PrivateRoute>
          }
        />

        {/* Vacancies  */}
        <Route
          path="/vacancies"
          element={
            <PrivateRoute>
              <ListVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/create"
          element={
            <PrivateRoute>
              <CreateVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/edit/:id"
          element={
            <PrivateRoute>
              <EditVacancyPage />
            </PrivateRoute>
          }
        />

        {/* Video  */}
        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <ListVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/create"
          element={
            <PrivateRoute>
              <CreateVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/edit/:id"
          element={
            <PrivateRoute>
              <EditVideoPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
