import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { VideoCreate } from '../video';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type VideoProps = {
  onSubmit: (
    value: VideoCreate,
    helpers: FormikHelpers<VideoCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateVideoForm = ({ onSubmit, submitting }: VideoProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: VideoCreate,
    helpers: FormikHelpers<VideoCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/videos/create', { replace: true });
    }
  };

  const initialValues: VideoCreate = {
    title: '',
    url: 'https://www.youtube.com/embed/',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="url"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Embed Link(video id)"
                      placeholder=""
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.url)}
                      helperText={formik.errors.url}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateVideoForm;
