import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Contact } from '../contact';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

const tableHeaders: TableHeader[] = [
  { field: 'name', label: 'Name', align: 'left' },
  { field: 'phone', label: 'Phone', align: 'left' },
  { field: 'email', label: 'Email', align: 'left' },
  { field: 'message', label: 'Message', align: 'left' },
];
const ContactList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`auth-xaea12/contacts${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Contact>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-xaea12/contacts/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <TableContainer
            modelToken="contacts"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Contacts"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{
              edit: '/contacts/edit',
              create: '/contacts/create',
              delete: '/contacts',
              view: '/contacts',
            }}
            settings={{
              canCreate: false,
              canEdit: false,
              canDelete: true,
              canViewItem: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default ContactList;
