import {
  Collections,
  DocumentScanner,
  DoneOutline,
  Email,
  Event as EventIcon,
  Feed,
  Group,
  Message,
  TrendingUp,
  VideoCall,
  Work,
} from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/bids"
                label="Bid"
                active={location.pathname.startsWith('/bids')}
                icon={<DocumentScanner />}
              />
              <SidebarLink
                to="/events"
                label="Event"
                active={location.pathname.startsWith('/events')}
                icon={<EventIcon />}
              />
              <SidebarLink
                to="/galleries"
                label="Gallery"
                active={location.pathname.startsWith('/galleries')}
                icon={<Collections />}
              />
              <SidebarLink
                to="/news"
                label="News"
                active={location.pathname.startsWith('/news')}
                icon={<Feed />}
              />
              <SidebarLink
                to="/resources"
                label="Resource"
                active={location.pathname.startsWith('/resources')}
                icon={<DocumentScanner />}
              />
              <SidebarLink
                to="/success-stories"
                label="Success Story"
                active={location.pathname.startsWith('/success-stories')}
                icon={<TrendingUp />}
              />
              <SidebarLink
                to="/teams"
                label="Team"
                active={location.pathname.startsWith('/teams')}
                icon={<Group />}
              />
              <SidebarLink
                to="/thematic-areas"
                label="Thematic Area"
                active={location.pathname.startsWith('/thematic-areas')}
              />
              <SidebarLink
                to="/vacancies"
                label="Vacancy"
                active={location.pathname.startsWith('/vacancies')}
                icon={<Work />}
              />
              <SidebarLink
                to="/videos"
                label="Video"
                active={location.pathname.startsWith('/videos')}
                icon={<VideoCall />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                SINGLE TYPES
              </Typography>

              <SidebarLink
                to="/impacts"
                label="Impact"
                active={location.pathname.startsWith('/impacts')}
                icon={<DoneOutline />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                FORMS
              </Typography>

              <SidebarLink
                to="/contacts"
                label="Contact"
                active={location.pathname.startsWith('/contacts')}
                icon={<Message />}
              />
              <SidebarLink
                to="/subscriptions"
                label="Subscriptions"
                active={location.pathname.startsWith('/subscriptions')}
                icon={<Email />}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
